<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{ mainTitle }}
      </el-col>
    </el-row>

    <el-row class="secondaryMiddleTitle">
      <el-col :class="center" style="text-align: center">
        Main Conference Speakers
      </el-col>
    </el-row>

    <!-- Prof Pascale Fung 	Prof Judy Kay	Prof Jiebo Luo
    The Hong Kong University of Science & Technology (HKUST) 
    Hong Kong	University of Sydney
    Australia	University of Rochester
    USA
      Presentation title:
    Empowering People to Harness and Control their Multimodal Data	
    Pascale Fung is a Chair Professor at the Department of Electronic & Computer Engineering at The Hong Kong University of Science & Technology (HKUST), and a visiting professor at the Central Academy of Fine Arts in Beijing. 

    She is an elected Fellow of the Association for the Advancement of Artificial Intelligence (AAAI) for her "significant contributions to the field of conversational AI and to the development of ethical AI principles and algorithms", an elected Fellow of the Association for Computational Linguistics (ACL) for her “significant contributions towards statistical NLP, comparable corpora, and building intelligent systems that can understand and empathize with humans”.

    She is an Fellow of the Institute of Electrical and Electronic Engineers (IEEE) for her “contributions to human-machine interactions” and an elected Fellow of the International Speech Communication Association for “fundamental contributions to the interdisciplinary area of spoken language human-machine interactions”.

    She is the Director of HKUST Centre for AI Research (CAiRE), an interdisciplinary research centre promoting human-centric AI. She co-founded the Human Language Technology Center (HLTC). She is an affiliated faculty with the Robotics Institute and the Big Data Institute at HKUST. She is the founding chair of the Women Faculty Association at HKUST. She is an expert on the Global Future Council, a think tank for the World Economic Forum. She represents HKUST on Partnership on AI to Benefit People and Society. She is on the Board of Governors of the IEEE Signal Processing Society. She is a member of the IEEE Working Group to develop an IEEE standard -  Recommended Practice for Organizational Governance of Artificial Intelligence. She was a Distinguished Consultant on Responsible AI at Meta in 2022, and a Visiting Faculty Researcher at Google in 2023. 

    Her research team has won several best and outstanding paper awards at ACL, ACL and NeurIPS workshops.
      Judy Kay is Professor of Computer Science. She leads the Human Centred Technology Research Cluster, in the Faculty of Engineering at the University of Sydney. 

    A core focus of her research has been to create infrastructures and interfaces for personalisation so that people can scrutinise and control them. She has created such systems to support people in lifelong, life-wide learning. This ranges from formal education settings to supporting people in using their long-term ubicomp data to support self-monitoring, reflection and planning and includes medical contexts such as learning communication skills in medical settings. She has integrated this into new forms of interaction including virtual reality, surface computing, wearables and ambient displays. Her research has been commercialised and deployed and she has extensive publications in leading venues for research in user modelling, AIED, human computer interaction and ubicomp.

    She has held leadership roles in top conferences in these areas and is Editor-in-Chief of the IJAIED, International Journal of Artificial Intelligence in Education (IJAIED), recent Editor and now Advisory Board member of IMWUT, Interactive Mobile Wearable and Ubiquitous Technology (IMWUT) and Advisory Board member of ACM Transactions on Interactive Intelligent Systems TiiS).
      Prof Jiebo Luo is the Albert Arendt Hopeman Professor of Engineering and Professor of Computer Science at the University of Rochester, which he joined in 2011 after a prolific career of fifteen years at Kodak Research Laboratories. 

    He has authored over 600 technical papers and holds over 90 U.S. patents. His research interests include computer vision, NLP, machine learning, data mining, computational social science, and digital health. He has been involved in numerous technical conferences, including serving as program co-chair of ACM Multimedia 2010, IEEE CVPR 2012, ACM ICMR 2016, and IEEE ICIP 2017, and general co-chair of ACM Multimedia 2018 and IEEE ICME 2024. 

    He has served on the editorial boards of the IEEE Transactions on Pattern Analysis and Machine Intelligence (TPAMI), IEEE Transactions on Multimedia (TMM), IEEE Transactions on Circuits and Systems for Video Technology (TCSVT), IEEE Transactions on Big Data (TBD), ACM Transactions on Intelligent Systems and Technology (TIST), Pattern Recognition, Knowledge and Information Systems (KAIS), Machine Vision and Applications (MVA), and Intelligent Medicine. He was the Editor-in-Chief of the IEEE Transactions on Multimedia (2020-2022). Professor Luo is a Fellow of ACM, AAAI, IEEE, SPIE, and IAPR, as well as a Member of Academia Europaea and the US National Academy of Inventors (NAI). Professor Luo received the ACM SIGMM Technical Achievement Award in 2021 and the William H. Riker University Award for Excellence in Graduate Teaching in 2024. -->



    <div>
      <table border="1">
        <tr align="center">
          <td style="width: 33.33%"><img :src="require('@/assets/keynotes/Pascale_Fung.png')" alt="Prof Pascale Fung" style="height: 200px;"></td>
          <td style="width: 33.33%"><img :src="require('@/assets/keynotes/Judy_Kay.png')" alt="Prof Judy Kay" style="height: 200px;"></td>
          <td style="width: 33.33%"><img :src="require('@/assets/keynotes/Jiebo_Luo.png')" alt="Prof Jiebo Luo" style="height: 200px;"></td>
        </tr>
        <tr align="center">
          <td><strong>Prof Pascale Fung</strong></td>
          <td><strong>Prof Judy Kay</strong></td>
          <td><strong>Prof Jiebo Luo</strong></td>
        </tr>
        <tr class="align-top" align="center">
          <td><strong>The Hong Kong University of Science & Technology (HKUST)</strong><br>Hong Kong</td>
          <td><strong>University of Sydney</strong><br>Australia</td>
          <td><strong>University of Rochester</strong><br>USA</td>
        </tr>
        <tr class="align-top" align="center">
          <td></td>
          <td>Presentation title:<br><strong>Empowering People to Harness and Control their Multimodal Data</strong></td>
          <td></td>
        </tr>
        <tr>
          <td class="align-top description">
            Pascale Fung is a Chair Professor at the Department of Electronic & Computer Engineering at The Hong Kong University of Science & Technology (HKUST), and a visiting professor at the Central Academy of Fine Arts in Beijing. 
            <br><br>
            She is an elected Fellow of the Association for the Advancement of Artificial Intelligence (AAAI) for her "significant contributions to the field of conversational AI and to the development of ethical AI principles and algorithms", an elected Fellow of the Association for Computational Linguistics (ACL) for her “significant contributions towards statistical NLP, comparable corpora, and building intelligent systems that can understand and empathize with humans”.
            <span v-if="!showPascaleFungBio">... <a @click="showPascaleFungBio = true">Read more</a></span>
            <span v-else>
              <br><br>
              She is an Fellow of the Institute of Electrical and Electronic Engineers (IEEE) for her “contributions to human-machine interactions” and an elected Fellow of the International Speech Communication Association for “fundamental contributions to the interdisciplinary area of spoken language human-machine interactions”. 
              <br><br>
              She is the Director of HKUST Centre for AI Research (CAiRE), an interdisciplinary research centre promoting human-centric AI. She co-founded the Human Language Technology Center (HLTC). She is an affiliated faculty with the Robotics Institute and the Big Data Institute at HKUST. She is the founding chair of the Women Faculty Association at HKUST. She is an expert on the Global Future Council, a think tank for the World Economic Forum. She represents HKUST on Partnership on AI to Benefit People and Society. She is on the Board of Governors of the IEEE Signal Processing Society. She is a member of the IEEE Working Group to develop an IEEE standard -  Recommended Practice for Organizational Governance of Artificial Intelligence. She was a Distinguished Consultant on Responsible AI at Meta in 2022, and a Visiting Faculty Researcher at Google in 2023. 
              <br><br>
              Her research team has won several best and outstanding paper awards at ACL, ACL and NeurIPS workshops.
            <a @click="showPascaleFungBio = false">Read less</a></span>
          </td>
          <td class="align-top description">
            Judy Kay is Professor of Computer Science. She leads the Human Centred Technology Research Cluster, in the Faculty of Engineering at the University of Sydney. 
            <br><br>
            A core focus of her research has been to create infrastructures and interfaces for personalisation so that people can scrutinise and control them. She has created such systems to support people in lifelong, life-wide learning. This ranges from formal education settings to supporting people in using their long-term ubicomp data to support self-monitoring, reflection and planning and includes medical contexts such as learning communication skills in medical settings.
            <span v-if="!showJudyKayBio">... <a @click="showJudyKayBio = true">Read more</a></span>
            <span v-else>
              She has integrated this into new forms of interaction including virtual reality, surface computing, wearables and ambient displays. Her research has been commercialised and deployed and she has extensive publications in leading venues for research in user modelling, AIED, human computer interaction and ubicomp.
              <br><br>
              She has held leadership roles in top conferences in these areas and is Editor-in-Chief of the IJAIED, International Journal of Artificial Intelligence in Education (IJAIED), recent Editor and now Advisory Board member of IMWUT, Interactive Mobile Wearable and Ubiquitous Technology (IMWUT) and Advisory Board member of ACM Transactions on Interactive Intelligent Systems TiiS).
            <a @click="showJudyKayBio = false">Read less</a></span>
          </td>
          <td class="align-top description">
            Prof Jiebo Luo is the Albert Arendt Hopeman Professor of Engineering and Professor of Computer Science at the University of Rochester, which he joined in 2011 after a prolific career of fifteen years at Kodak Research Laboratories. 
            <br><br>
            He has authored over 600 technical papers and holds over 90 U.S. patents. His research interests include computer vision, NLP, machine learning, data mining, computational social science, and digital health. He has been involved in numerous technical conferences, including serving as program co-chair of ACM Multimedia 2010, IEEE CVPR 2012, ACM ICMR 2016, and IEEE ICIP 2017, and general co-chair of ACM Multimedia 2018 and IEEE ICME 2024. 
            <span v-if="!showJieboLuoBio">... <a @click="showJieboLuoBio = true">Read more</a></span>
            <span v-else> <br><br>
              He has served on the editorial boards of the IEEE Transactions on Pattern Analysis and Machine Intelligence (TPAMI), IEEE Transactions on Multimedia (TMM), IEEE Transactions on Circuits and Systems for Video Technology (TCSVT), IEEE Transactions on Big Data (TBD), ACM Transactions on Intelligent Systems and Technology (TIST), Pattern Recognition, Knowledge and Information Systems (KAIS), Machine Vision and Applications (MVA), and Intelligent Medicine. He was the Editor-in-Chief of the IEEE Transactions on Multimedia (2020-2022). Professor Luo is a Fellow of ACM, AAAI, IEEE, SPIE, and IAPR, as well as a Member of Academia Europaea and the US National Academy of Inventors (NAI). Professor Luo received the ACM SIGMM Technical Achievement Award in 2021 and the William H. Riker University Award for Excellence in Graduate Teaching in 2024.
            <a @click="showJieboLuoBio = false">Read less</a></span>
          </td>
        </tr>

      </table>
    </div>

    <el-row class="secondaryMiddleTitle">
      <el-col :class="center" style="text-align: center">
        Industry Speakers
      </el-col>
    </el-row>

    
    <!-- Dr Benoit Huet
    MEDIAN Technologies
    France
    Presentation title:
    Revolutionizing Lung Cancer Diagnostics with eyonis™LCS: cutting-edge AI/ML technology-based SaMD for Enhanced Patient Care
    Benoit Huet joined MEDIAN Technologies in 2019 where he holds the position of AI & Data Science director, managing a team of talented multicultural and multidisciplinary individuals composed of Clinical Data Managers, Data and Knowledge Engineers and Data Scientists.

    He was previously Assistant Professor in the Data Science Department of Eurecom (France) from 1999 to 2019. Before joining Median, Benoit was Associate Professor in the Data Science Department of EURECOM (France). During his academic career, he visited both IBM T.J. Watson and National University of Singapore as Visiting Researcher.

    He obtained the HDR (Habilitation to Direct Research) from the University of Nice Sophia Antipolis (France) in October 2012 on the topic of Multimedia Content Understanding: Bringing Context to Content. He received his DPhil degree in Computer Science from the University of York (UK) for his research on the topic of object recognition from large databases. He was awarded the MSc degree in Artificial Intelligence from the University of Westminster (UK) with distinction in 1993.

    He authored and co-authored over 200 publications in Books, Journals and Conferences, gave many keynotes and took part in several panels on AI and Multimedia at major international events.

    He chaired a number of international conference: such as IEEE MIPR2021, ACM Multimedia 2019, MMM2019, PCM2014 and multiple workshops. Benoit was chairing the IEEE MMTC Interest Group on Visual Analysis, Interaction and Content Management from 2010-1014.
    He served as associate editor for several journals, IEEE Multimedia Magazine, IEEE Transaction on Multimedia, Multimedia Tools and Application (Springer) and Multimedia Systems (Springer). He regularly served on the technical program committee of the top conference of the field (ACM MM/ICMR, IEEE ICME/ICIP).

    His current research interests include AI for Healthcare, Multimodal Analysis and Fusion, Large Scale Medical Image Analysis, Mining and Indexing. -->
    <div>
      <table border="1" style="width: 50%;" align="center">
        <tr align="center">
          <td><img :src="require('@/assets/keynotes/Benoit_HUET.jpg')" alt="Dr Benoit HUET"></td>
        </tr>
        <tr align="center">
          <td><strong>Dr Benoit HUET</strong></td>
        </tr>
        <tr class="align-top" align="center">
          <td>MEDIAN Technologies<br>France</td>
        </tr>
        <tr class="align-top" align="center">
          <td>Presentation title:<br><strong>Revolutionizing Lung Cancer Diagnostics with eyonis™LCS: cutting-edge AI/ML technology-based SaMD for Enhanced Patient Care</strong></td>
        </tr>
        <tr>
          <td class="align-top description">
            Benoit Huet joined MEDIAN Technologies in 2019 where he holds the position of AI & Data Science director, managing a team of talented multicultural and multidisciplinary individuals composed of Clinical Data Managers, Data and Knowledge Engineers and Data Scientists. 
            <br><br>
            He was previously Assistant Professor in the Data Science Department of Eurecom (France) from 1999 to 2019. Before joining Median, Benoit was Associate Professor in the Data Science Department of EURECOM (France). During his academic career, he visited both IBM T.J. Watson and National University of Singapore as Visiting Researcher. 
            <span v-if="!showBenoitHuetBio">... <a @click="showBenoitHuetBio = true">Read more</a></span>
            <span v-else> <br><br>
              He obtained the HDR (Habilitation to Direct Research) from the University of Nice Sophia Antipolis (France) in October 2012 on the topic of Multimedia Content Understanding: Bringing Context to Content. He received his DPhil degree in Computer Science from the University of York (UK) for his research on the topic of object recognition from large databases. He was awarded the MSc degree in Artificial Intelligence from the University of Westminster (UK) with distinction in 1993. 
              <br><br>
              He authored and co-authored over 200 publications in Books, Journals and Conferences, gave many keynotes and took part in several panels on AI and Multimedia at major international events. 
              <br><br>
              He chaired a number of international conference: such as IEEE MIPR2021, ACM Multimedia 2019, MMM2019, PCM2014 and multiple workshops. Benoit was chairing the IEEE MMTC Interest Group on Visual Analysis, Interaction and Content Management from 2010-1014. 
              <br><br>
              He served as associate editor for several journals, IEEE Multimedia Magazine, IEEE Transaction on Multimedia, Multimedia Tools and Application (Springer) and Multimedia Systems (Springer). He regularly served on the technical program committee of the top conference of the field (ACM MM/ICMR, IEEE ICME/ICIP). 
              <br><br>
              His current research interests include AI for Healthcare, Multimodal Analysis and Fusion, Large Scale Medical Image Analysis, Mining and Indexing.
            <a @click="showBenoitHuetBio = false">Read less</a></span>
          </td>
        </tr>

      </table>
    </div>


    <div v-for="(item,index) in Keynote" :key="index" :id="item.id">
      <div v-for="(content,index) in item.contents" :key="index">
      <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
        <!--熊猫图标-->
        <el-col :span="2">
          <img class="icon" :src="iconImage" alt="">
        </el-col>
        <!--title-->
        <el-col :span="22">
          <p class="title">{{ content.text }}</p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'text'">
        <el-col>
          <p class="demo-text" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryBlack'">
        <el-col class="secondaryTitle">
          <p class="secondaryBlack" v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'secondaryTitle'">
        <el-col class="secondaryTitle">
          <p v-html="content.text"></p>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'noList'">
        <el-col>
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract'">
        <el-col class="retract">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>
      <el-row v-else-if="content.type === 'noListBlack'">
        <el-col>
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retractBlack'">
        <el-col class="retract">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2'">
        <el-col class="retract2">
          <li class="noList">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'retract2Black'">
        <el-col class="retract2">
          <li class="noList black-text">
            <div v-html="content.text"></div>
          </li>
        </el-col>
      </el-row>

      <el-row v-else-if="content.type === 'textBlue'">
        <el-col>
          <p class="textBlue" v-html="content.text"></p>
        </el-col>
      </el-row>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "keynotes",
  data() {
    return {
      showPascaleFungBio: false,
      showJudyKayBio: false,
      showJieboLuoBio: false,
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Conference Keynotes'
  }
  ,
  methods: {
    goAnchor(id) {
      var anchor = document.getElementById(id);
      // chrome
      document.body.scrollTop = anchor.offsetTop;
      // firefox
      document.documentElement.scrollTop = anchor.offsetTop;
      // safari
      window.pageYOffset = anchor.offsetTop;
    }
  }
}

</script>

<style scoped>
@import "../../css/responsive.css";

.photo {
  width: 28%;
  margin-top: 1.8rem;
  vertical-align: top;
  /*display: inline-block;*/
}

.parent {
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: -3rem;
}

.personnel {
  float: left;
  width: 22rem;
  height: 36rem;
  background: #FFFFFF;
  border-radius: 1.8rem;
  border: 2px solid #EEEEEE;
  margin-right: 2rem;
  margin-left: 2rem;
  text-align: center;
  /*margin-bottom: -4rem;*/

}

.personnel:hover {
  box-shadow: 0 0.2rem 0.5rem 0 #B3B3B3;
}

.personnels {
  text-align: center;
  margin-bottom: 2rem;
}

.pre-header > div {
  width: 19rem;
  height: 19rem;
  /*background-color: red;*/
  margin: 1rem auto 1rem;
  border-radius: 1rem;
}

.pre-header > div > img {
  width: 100%;
}

.pre-title > p {
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #0054B0;
  margin-top: 0.6rem;
  /*line-height: 19px;*/
}

.pre-middle > p {
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #272727;
  margin-top: 0.6rem;
  /*line-height: 19px;*/
}

.pre-button {
  padding-left: .5rem;
  padding-right: .5rem;
  margin-top: 0.6rem;

}

.pre-button > p {
  font-size: 1.4rem;
  margin-right: 1rem;
  margin-left: 1rem;
  font-family: Roboto-Medium, Roboto, sans-serif;
  font-weight: 500;

  color: #272727;
  /*line-height: 16px;*/
}

.pre {
  margin-top: 2rem;
}

@media screen  and (max-width: 750px) {
  .personnel p {
    margin: 0 0 .8rem !important;
  }

  .parent .personnel:first-child {
    margin-left: 2rem;
  }

  .personnel {
    margin-right: 1.2rem;
  }

  .personnels {
    width: 105%;
  }
}

.parent > div:first-child {
  margin-left: 2rem !important;
}

.align-top {
  vertical-align: top; /* 设置垂直对齐方式为顶部 */
}

</style>
